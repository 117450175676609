import { FormControl, FormGroup } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';

// Validators
export function RequiredValidator(formControl: FormControl, field: FormlyFieldConfig): boolean {
    if (field['ta_validators'].required) {
        return formControl.value ? true : false;
    }
    return true;
}