import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetClientsState, UpdateClientsActivePage, UpdateClientsPageSize, UpdateClientsSearchQuery, UpdateClientsSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface ClientsStateModel extends TableState { }

const CLIENTS_STATE_DEFAULT: ClientsStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'name',
        direction: 'asc',
        name: 'Name',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<ClientsStateModel>({
    name: 'clients',
    defaults: CLIENTS_STATE_DEFAULT
})

@Injectable()
export class ClientsState {

    @Selector()
    static getClientsState(state: ClientsStateModel): ClientsStateModel {
        return state;
    }

    @Action(UpdateClientsPageSize)
    public updateClientsPageSize(ctx: StateContext<ClientsStateModel>, action: UpdateClientsPageSize) {
        ctx.patchState({
            ...ctx.getState(),
            pageSize: action.pageSize,
            filtered: true
        });
    }

    @Action(UpdateClientsSearchQuery)
    public updateClientsSearchQuery(ctx: StateContext<ClientsStateModel>, action: UpdateClientsSearchQuery) {
        ctx.patchState({
            ...ctx.getState(),
            searchQuery: action.searchQuery,
            filtered: true
        });
    }

    @Action(UpdateClientsActivePage)
    public updateClientsActivePage(ctx: StateContext<ClientsStateModel>, action: UpdateClientsActivePage) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: action.activePage,
            filtered: true
        });
    }

    @Action(UpdateClientsSort)
    public updateClientsSort(ctx: StateContext<ClientsStateModel>, action: UpdateClientsSort) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: 0,
            sort: {
                prop: action.sort.prop,
                direction: action.sort.direction,
                name: action.sort.name,
                default: false
            },
            filtered: true
        });
    }

    @Action(ResetClientsState)
    public resetClientsState(ctx: StateContext<ClientsStateModel>, action: ResetClientsState) {
        ctx.setState(CLIENTS_STATE_DEFAULT);
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: CLIENTS_STATE_DEFAULT.sort.prop, dir: CLIENTS_STATE_DEFAULT.sort.direction }] });
        }
    }
}