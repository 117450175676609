import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { FormlyModule } from '@ngx-formly/core';
import { provideStore } from '@ngxs/store';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { FormlyComponentTypes } from '@formly/ta-formly';
import { TaFieldWrapperComponent } from '@formly/ta-field-wrapper/ta-field-wrapper.component';

import { networkInterceptor } from '@interceptors/network.interceptor';

import { ClientState, UserState } from '@state-management/states';

import { environment } from '@environments/environment';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimations(),
		provideHttpClient(
			withInterceptors([networkInterceptor])
		),
		provideStore(
			[ClientState, UserState], { developmentMode: !environment.production },
			withNgxsStoragePlugin({
				keys: []
			})
		),
		importProvidersFrom(
			// ModalModule.forRoot(),
			ToastrModule.forRoot({
				timeOut: 3000,
				closeButton: true,
				enableHtml: true,
				tapToDismiss: false,
				titleClass: 'alert-title',
				positionClass: 'toast-top-center',
			}),
			FormlyModule.forRoot({
				validationMessages: [
					{ name: 'required', message: 'This field is required' },
				],
				wrappers: [
					{
						name: 'ta-field-wrapper',
						component: TaFieldWrapperComponent
					}
				],
				types: FormlyComponentTypes
			}),
		),
		{ provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }
	]
};
