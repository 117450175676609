import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appFormControlFocus]',
    standalone: true
})
export class FormControlFocusDirective {

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) { }

    @HostListener('focus', ['$event.target'])
    public onFocus(target: any) {
        const formGroupNode = this.el.nativeElement.parentNode?.parentNode;
        if (formGroupNode && formGroupNode.className?.includes('form-group')) {
            this.renderer.addClass(formGroupNode, 'focused');
        }
    }

    @HostListener('blur', ['$event.target'])
    public onBlur(target: any) {
        const formGroupNode = this.el.nativeElement.parentNode?.parentNode;
        if (formGroupNode && formGroupNode.className?.includes('form-group')) {
            this.renderer.removeClass(formGroupNode, 'focused');
        }
    }
}
