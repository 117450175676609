import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { ConfigService, StorageService } from '@services';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LoginGuard implements CanActivate {

    constructor(
        private configService: ConfigService,
        private router: Router,
        private storageService: StorageService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.storageService.getFromStorage(this.configService.userSessionKey)) {
            this.router.navigate(['/dashboard/home']);
            return false;
        } else {
            return true;
        }
    }

}
