import { ReportFormlyField } from "@models/reports";

export const AuditSettingsFormlyFields = (): ReportFormlyField[] => {
    return [
        {
            key: 'show_guidance',
            ta_validators: {
                required: false
            },
            templateOptions: {
                'label': 'Show Guidance'
            },
            type: 'ta-toggle',
            uuid: 'cf791422644f462f97f8c67b7be1120d'
        },
        {
            key: 'show_timestamps',
            ta_validators: {
                required: false
            },
            templateOptions: {
                label: 'Show Timestamps'
            },
            type: 'ta-toggle',
            uuid: '41a81fa3420d4a8e9aad744a6a09252c'
        },
        {
            key: 'show_gps',
            ta_validators: {
                required: false
            },
            templateOptions: {
                label: 'Show Gps'
            },
            type: 'ta-toggle',
            uuid: '6cc40d1f0849487591e2abf0f0ef9b9f'
        },
        {
            key: 'show_all',
            ta_validators: {
                required: false
            },
            templateOptions: {
                label: 'Show All Question Options'
            },
            type: 'ta-toggle',
            uuid: 'cc704726c6ef4987bff4173d330d2fab'
        }
    ]
};