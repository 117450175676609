import { Component } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                window.scrollTo(0, 0);
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                if (event.url !== 'login') {
                    if (event.error?.status === 0) {
                        const returnUrl: string = this.activatedRoute.snapshot.queryParams.return_url;
                        if (event.url && !returnUrl) {
                            this.router.navigate(['/server-status'], { queryParams: { return_url: encodeURI(event.url) } });
                        } else {
                            this.router.navigate(['/server-status'], { queryParamsHandling: 'preserve' });
                        }
                    }
                }

            }
        });
    }
}
