export const environment = {
    production: false,
    logToConsole: true,
    dashboardAuthApiUrl: 'https://devdashboard2.tickaudit.com/api/v1/auth',
    dashboardClientApiUrl: 'https://devdashboard2.tickaudit.com/api/v1/client',
    previewUrl: 'https://devpreview.tickaudit.com',
    dashboardUrl: 'https://devdashboard2.tickaudit.com',
    templateBuilderUrl: 'https://devbuilder.tickaudit.com',
    stripePk: 'pk_test_N6uMXCPTKRh74H4TfzwwnpD6',
    secretKey: 'Pu8YhD%$&8o:x/U',
    brand: {
        name: 'TickAudit',
        siteUrl: 'https://tickaudit.com',
        iconDark: 'tickaudit-icon-dark.png',
        iconLight: 'tickaudit-icon-light.png',
        logoDark: 'tickaudit-logo-dark.png',
        logoLight: 'tickaudit-logo-light.png',
        poweredByLogo: 'tickaudit-powered-by-logo.png'
    },
    debug: false
};
