import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetAuditTemplatesState, UpdateAuditTemplatesActivePage, UpdateAuditTemplatesPageSize, UpdateAuditTemplatesSearchQuery, UpdateAuditTemplatesSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface AuditTemplatesStateModel extends TableState { }

const AUDIT_TEMPLATES_STATE_DEFAULT: AuditTemplatesStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'title',
        direction: 'asc',
        name: 'Title',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<AuditTemplatesStateModel>({
    name: 'auditTemplates',
    defaults: AUDIT_TEMPLATES_STATE_DEFAULT
})

@Injectable()
export class AuditTemplatesState {

    @Selector()
    static getAuditTemplatesState(state: AuditTemplatesStateModel): AuditTemplatesStateModel {
        return state;
    }

    @Action(UpdateAuditTemplatesPageSize)
    public updateAuditTemplatesPageSize(ctx: StateContext<AuditTemplatesStateModel>, action: UpdateAuditTemplatesPageSize) {
        ctx.patchState({
            ...ctx.getState(),
            pageSize: action.pageSize,
            filtered: true
        });
    }

    @Action(UpdateAuditTemplatesSearchQuery)
    public updateAuditTemplatesSearchQuery(ctx: StateContext<AuditTemplatesStateModel>, action: UpdateAuditTemplatesSearchQuery) {
        ctx.patchState({
            ...ctx.getState(),
            searchQuery: action.searchQuery,
            filtered: true
        });
    }

    @Action(UpdateAuditTemplatesActivePage)
    public updateAuditTemplatesActivePage(ctx: StateContext<AuditTemplatesStateModel>, action: UpdateAuditTemplatesActivePage) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: action.activePage,
            filtered: true
        });
    }

    @Action(UpdateAuditTemplatesSort)
    public updateAuditTemplatesSort(ctx: StateContext<AuditTemplatesStateModel>, action: UpdateAuditTemplatesSort) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: 0,
            sort: {
                prop: action.sort.prop,
                direction: action.sort.direction,
                name: action.sort.name,
                default: false
            },
            filtered: true
        });
    }

    @Action(ResetAuditTemplatesState)
    public resetAuditTemplatesState(ctx: StateContext<AuditTemplatesStateModel>, action: ResetAuditTemplatesState) {
        ctx.setState(AUDIT_TEMPLATES_STATE_DEFAULT);
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: AUDIT_TEMPLATES_STATE_DEFAULT.sort.prop, dir: AUDIT_TEMPLATES_STATE_DEFAULT.sort.direction }] });
        }
    }
}