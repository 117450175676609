import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
	selector: 'app-ta-checkbox',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	templateUrl: './ta-checkbox.component.html',
	styleUrl: './ta-checkbox.component.scss'
})
export class TaCheckboxComponent extends FieldType<TaFormlyFieldConfig> {

	constructor() {
		super();
	}
}
