import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import * as dayjs from 'dayjs';
import { get } from 'lodash-es';

import { FormControlFocusDirective } from '@directives/form-control-focus.directive';
import { FormControlValidationDirective } from '@directives/form-control-validation.directive';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
    selector: 'app-ta-date',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, BsDatepickerModule, FormControlFocusDirective, FormControlValidationDirective],
    templateUrl: './ta-date.component.html',
    styleUrls: ['./ta-date.component.scss']
})

export class TaDateComponent extends FieldType<TaFormlyFieldConfig> implements OnInit {

    public date: Date;
    public bsConfigValue = { isAnimated: true, containerClass: 'theme-dark-blue', showClearButton: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' };

    constructor() {
        super();
    }

    ngOnInit() {
        const value = get(this.model, this.key, '');
        if (value) {
            this.date = dayjs(value).toDate();
        }
    }

    public dateChanged(value: any) {
        const formattedDate = dayjs(value).toISOString();
        this.formControl.setValue(formattedDate);
    }

}
