import { TaCheckboxComponent } from './ta-checkbox/ta-checkbox.component';
import { TaDateComponent } from '@formly/ta-date/ta-date.component';
import { TaInputComponent } from '@formly/ta-input/ta-input.component';
import { TaToggleComponent } from '@formly/ta-toggle/ta-toggle.component';

import { RequiredValidator } from '@formly/ta-validators';

export const FormlyComponentTypes = [
    {
        name: 'ta-checkbox',
        component: TaCheckboxComponent,
        defaultOptions: {
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-date',
        component: TaDateComponent,
        defaultOptions: {
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-input',
        component: TaInputComponent,
        defaultOptions: {
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-toggle',
        component: TaToggleComponent,
        defaultOptions: {
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    }
];