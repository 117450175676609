import { Injectable } from '@angular/core';

import { ConfigService, StorageService } from '@services';

import { Session } from '@models/shared';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    constructor(
        private configService: ConfigService,
        private storageService: StorageService
    ) { }

    public getCurrentSession() {
        const userSession = this.storageService.getFromStorage<Session>(this.configService.userSessionKey);
        return userSession;
    }
}
