import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TaFormlyFieldConfig } from '@formly/ta-formly-types';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'app-ta-field-wrapper',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './ta-field-wrapper.component.html',
	styleUrl: './ta-field-wrapper.component.scss'
})
export class TaFieldWrapperComponent extends FieldWrapper {
	declare field: TaFormlyFieldConfig;

	constructor() {
		super();
	}
}
