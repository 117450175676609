import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetUsersState, UpdateUsersActivePage, UpdateUsersPageSize, UpdateUsersSearchQuery, UpdateUsersSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface UsersStateModel extends TableState { }

const USERS_STATE_DEFAULT: UsersStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'contact.first_name',
        direction: 'asc',
        name: 'Name',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<UsersStateModel>({
    name: 'users',
    defaults: USERS_STATE_DEFAULT
})

@Injectable()
export class UsersState {

    @Selector()
    static getUsersState(state: UsersStateModel): UsersStateModel {
        return state;
    }

    @Action(UpdateUsersPageSize)
    public updateUsersPageSize(ctx: StateContext<UsersStateModel>, action: UpdateUsersPageSize) {
        ctx.patchState({
            ...ctx.getState(),
            pageSize: action.pageSize,
            filtered: true
        });
    }

    @Action(UpdateUsersSearchQuery)
    public updateUsersSearchQuery(ctx: StateContext<UsersStateModel>, action: UpdateUsersSearchQuery) {
        ctx.patchState({
            ...ctx.getState(),
            searchQuery: action.searchQuery,
            filtered: true
        });
    }

    @Action(UpdateUsersActivePage)
    public updateUsersActivePage(ctx: StateContext<UsersStateModel>, action: UpdateUsersActivePage) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: action.activePage,
            filtered: true
        });
    }

    @Action(UpdateUsersSort)
    public updateUsersSort(ctx: StateContext<UsersStateModel>, action: UpdateUsersSort) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: 0,
            sort: {
                prop: action.sort.prop,
                direction: action.sort.direction,
                name: action.sort.name,
                default: false
            },
            filtered: true
        });
    }

    @Action(ResetUsersState)
    public resetUsersState(ctx: StateContext<UsersStateModel>, action: ResetUsersState) {
        ctx.setState(USERS_STATE_DEFAULT);
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: USERS_STATE_DEFAULT.sort.prop, dir: USERS_STATE_DEFAULT.sort.direction }] });
        }
    }
}