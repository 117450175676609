import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";

import { finalize } from "rxjs";

import { NetworkLoaderService } from "@services";

export const networkInterceptor: HttpInterceptorFn = (request, next) => {
    const networkLoaderService = inject(NetworkLoaderService);
    networkLoaderService.show();
    return next(request).pipe(
        finalize(() => {
            networkLoaderService.hide();
        })
    );

}
