import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import swal from 'sweetalert2';
import { Store } from '@ngxs/store';

import { map } from 'rxjs';

import { ConfigService, StorageService, UserService } from '@services';

import { ResetAssetTemplatesState, ResetAuditTemplatesState, ResetClientSelectionState, ResetClientsState, ResetReportsState, ResetUsersState } from '@state-management/actions';

import { Asset } from '@models/assets';
import { Audit } from '@models/audits';
import { Client } from '@models/clients';
import { AuditsWithReports } from '@models/custom';
import { Image } from '@models/images';
import { StandardReport } from '@models/reports';
import { UserContact } from '@models/shared';
import { User, UserSignature } from '@models/users';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private store: Store,
        private userService: UserService
    ) { }

    // ASSETS

    public getAsset(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/asset/' + assetId;
        return this.makeRequest<Asset>('get', url);
    }

    // AUDITS

    public archiveAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/archive';
        return this.makeRequest<string>('post', url);
    }

    public deleteAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId;
        return this.makeRequest<string>('delete', url);
    }

    public getAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId;
        return this.makeRequest<(Audit & { contact: UserContact })>('get', url);
    }

    public getAuditWithReports(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/with_report';
        return this.makeRequest<(Audit & { contact: UserContact; reports: Partial<Report>[] })>('get', url);
    }

    public listAuditsWithReports(auditTypes: string[], startDate: string, endDate: string, failures: string[], archived: boolean = false, incomplete: boolean = false, signOffRequired: boolean = false) {
        const url = this.configService.dashboardClientApiUrl + '/audits/with_reports';
        const body = {
            audit_types: auditTypes,
            start_date: startDate,
            end_date: endDate,
            failures,
            archived,
            incomplete,
            sign_off_required: signOffRequired
        };

        return this.makeRequest<(AuditsWithReports[])>('post', url, body);
    }

    public reassignAudit(auditId: string, userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/reassign';
        const body = {
            user_id: userId
        }
        return this.makeRequest<string>('post', url, body);
    }

    public reopenAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/reopen';
        return this.makeRequest<string>('post', url, {});
    }

    public signOff(auditId: string, comments: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/sign_off';
        const body = { comments };
        return this.makeRequest<string>('post', url, body);
    }

    // CLIENTS

    public getClient(clientId: string) {
        const url = this.configService.dashboardClientApiUrl + '/clients/' + clientId;
        return this.makeRequest<Client>('get', url);
    }

    // DASHBOARD

    // IMAGES

    public getImage(imageId: string) {
        const url = this.configService.dashboardClientApiUrl + '/images/image/' + imageId;
        return this.makeRequest<Image>('get', url);
    }

    public getImageThumbnail(imageId: string) {
        const url = this.configService.dashboardClientApiUrl + '/images/thumbnail/' + imageId;
        return this.makeRequest<string>('get', url);
    }

    public getImageIdsByAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/images/ids_by_audit/' + auditId;
        return this.makeRequest<{ [key: string]: { id: string } }>('get', url);
    }

    // USERS

    public getUser(userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/' + userId;
        return this.makeRequest<User>('get', url);
    }

    public getUserSignature(signatureId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/signature/' + signatureId;
        return this.makeRequest<UserSignature>('get', url);
    }

    public listUsersByStatus(status: 'enabled' | 'disabled' | 'archived', includeArchived = false, usersRequiringUpdate = false, reviewDatePassed = false) {
        const url = this.configService.dashboardClientApiUrl + '/users/by_status/' + status;
        const body = {
            include_archived: includeArchived,
            users_requiring_update: usersRequiringUpdate,
            review_date_passed: reviewDatePassed
        }
        return this.makeRequest<User[]>('post', url, body);
    }

    public logoutUser(userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/users/logout/' + userId;
        return this.makeRequest<string>('get', url);
    }

    // REPORTS

    public generateBackendExcel(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/reports/generate_backend_excel/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, excel: any }>('post', url, body);
    }

    public generateExcel(auditId: string, reportId: string, reportParams: Record<string, any>) {
        const url = this.configService.dashboardClientApiUrl + '/reports/custom_reports/generate_excel/' + auditId;
        const body = {
            report_id: reportId,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, base64: string }>('post', url, body);
    }

    public generateBackendPdf(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/reports/generate_backend_pdf/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public generatePdf(auditId: string, reportId: string, reportParams: Record<string, any>) {
        const url = this.configService.dashboardClientApiUrl + '/reports/custom_reports/generate_pdf/' + auditId;
        const body = {
            report_id: reportId,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public listStandardReports(status: string) {
        let url = this.configService.dashboardClientApiUrl + '/reports/standard_reports/' + status;
        return this.makeRequest<StandardReport[]>('get', url);
    }

    // HELPER FUNCTIONS

    private makeRequest<T>(method: 'get' | 'post' | 'put' | 'delete', url: string, body?: any, responseType?: any, params?: any) {
        return new Promise<T>((resolve, reject) => {
            const headers = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.userService.getCurrentSession().session_id
                }
            );

            const options = {
                headers,
                responseType: null,
                params: null
            };

            if (responseType) {
                options.responseType = responseType;
            }

            if (params) {
                options.params = params;
            }

            const handleError = (error: any) => {
                if (error.error && (error.error === 'Invalid user' || error.error === 'Session expired' || error.error === 'Invalid session')) {
                    swal.fire({
                        title: 'Session Expired',
                        text: 'Your session has expired. Please sign in again.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.storageService.removeFromStorage(this.configService.userSessionKey);
                            this.router.navigate(['/login']);
                            this.store.dispatch([ResetClientsState, ResetAuditTemplatesState, ResetAssetTemplatesState, ResetReportsState, ResetUsersState, ResetClientSelectionState]);
                        }
                    });
                } else if (error.error && (error.error === 'No response')) {
                    swal.fire({
                        title: 'Error Connecting To Gateway',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                } else if (!error.error) {
                    swal.fire({
                        title: 'Unexpected Error',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                } else {
                    reject(error);
                }
            };

            if (method === 'get') {
                this.httpClient.get<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'post') {
                this.httpClient.post<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'put') {
                this.httpClient.put<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'delete') {
                this.httpClient.delete<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            }
        });
    }
}
